function forsaleModal() {
  var container = $('.forsale-modal-container');
  $('.forsale-img').on('click', function(e) {
    e.preventDefault();
    var src = $(this).attr('src');
    console.log(src);
    $('.forsale-modal').html("<img src='" + src + "'>");
    container.toggleClass('is-open');
  });
  container.on('click', function(e) {
    e.preventDefault();
    $(this).toggleClass('is-open');
  });
}


$(function() {
  forsaleModal();
});
